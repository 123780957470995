import { Injectable, signal, WritableSignal } from '@angular/core';
import { defaultDuration, NotificationType } from './toast.constants';

export interface Notification {
    id: number;
    title?: string;
    message: string;
    type: NotificationType;
}

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private currentId = 0;
    public notifications: WritableSignal<Notification[]> = signal([]);

    showNotification(value: Pick<Notification, 'title' | 'message' | 'type'>, duration = defaultDuration) {
        const notification: Notification = { ...value, id: this.currentId++ };

        this.notifications.update((list) => [...list, notification]);

        setTimeout(() => this.removeNotification(notification.id), duration);
    }

    removeNotification(id: number) {
        this.notifications.update((list) => list.filter((n) => n.id !== id));
    }
}
